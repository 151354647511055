@tailwind base;
@tailwind components;
@tailwind utilities;
/**
* Add css variables here as well for light mode in addition to tailwind.config to avoid FOUC
*/
:root {
  /* background */

  --cal-bg-emphasis: #e5e7eb;
  --cal-bg: white;
  --cal-bg-subtle: #f3f4f6;
  --cal-bg-muted: #f9fafb;
  --cal-bg-inverted: #111827;

  /* background -> components*/
  --cal-bg-info: #dee9fc;
  --cal-bg-success: #e2fbe8;
  --cal-bg-attention: #fceed8;
  --cal-bg-error: #f9e3e2;

  /* Borders */
  --cal-border-emphasis: #9ca3af;
  --cal-border: #d1d5db;
  --cal-border-subtle: #e5e7eb;
  --cal-border-booker: #e5e7eb;
  --cal-border-muted: #f3f4f6;

  /* Content/Text */
  --cal-text-emphasis: #111827;
  --cal-text: #374151;
  --cal-text-subtle: #6b7280;
  --cal-text-muted: #9ca3af;
  --cal-text-inverted: white;

  /* Content/Text -> components */
  --cal-text-info: #253985;
  --cal-text-success: #285231;
  --cal-text-attention: #73321b;
  --cal-text-error: #752522;

  /* Brand shinanigans
      -> These will be computed for the users theme at runtime.
    */
  --cal-brand: #111827;
  --cal-brand-emphasis: #101010;
  --cal-brand-text: white;
}
.dark {
  /* background */

  --cal-bg-emphasis: #2b2b2b;
  --cal-bg: #101010;
  --cal-bg-subtle: #2b2b2b;
  --cal-bg-muted: #1c1c1c;
  --cal-bg-inverted: #f3f4f6;

  /* background -> components*/
  --cal-bg-info: #dee9fc;
  --cal-bg-success: #e2fbe8;
  --cal-bg-attention: #fceed8;
  --cal-bg-error: #f9e3e2;

  /* Borders */
  --cal-border-emphasis: #575757;
  --cal-border: #444444;
  --cal-border-subtle: #2b2b2b;
  --cal-border-booker: #2b2b2b;
  --cal-border-muted: #1c1c1c;

  /* Content/Text */
  --cal-text-emphasis: #f3f4f6;
  --cal-text: #d6d6d6;
  --cal-text-subtle: #a5a5a5;
  --cal-text-muted: #575757;
  --cal-text-inverted: #101010;

  /* Content/Text -> components */
  --cal-text-info: #253985;
  --cal-text-success: #285231;
  --cal-text-attention: #73321b;
  --cal-text-error: #752522;

  /* Brand shenanigans
      -> These will be computed for the users theme at runtime.
    */
  --cal-brand: white;
  --cal-brand-emphasis: #e1e1e1;
  --cal-brand-text: black;
}

::-moz-selection {
  color: var(--cal-brand-text);
  background: var(--cal-brand);
}

::selection {
  color: var(--cal-brand-text);
  background: var(--cal-brand);
}

html,
body  {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;

  @apply font-body text-gray-dark-2;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

input[type=checkbox]{@apply rounded-full checked:bg-navy dark:checked:bg-teal checked:opacity-[50%]}
input[type=checkbox]:checked + label {
  @apply line-through opacity-[50%]
}
a {
  color: inherit;
  text-decoration: none;
}

h1 {
  @apply font-heading text-gray-dark-2 text-4xl font-normal leading-[100%];
}
h2 {
  @apply font-heading text-gray-dark-2 text-4xl font-normal leading-[100%];
}

span {
  @apply font-body text-base;
}

p {
  @apply font-body text-base;
}

input {
  @apply font-body;
}



input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  @apply hover:cursor-pointer disabled:hover:cursor-default
}

                        /***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
  opacity: 70%;
  background-color: #666666;
  height: 1px;
  @apply disabled:hover:cursor-default
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
  opacity: 70%;
  background: #666666;
  height: 1px;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  @apply disabled:hover:cursor-default border border-solid border-[2px] border-black rounded-full bg-transparent h-[8px] w-[8px] -mt-[3.5px] disabled:hidden
}

/***** Firefox *****/
input[type="range"]::-moz-range-thumb {
  margin-top: -3.5px; /* Centers thumb on the track */
  background-color: transparent;
  height: 8px;
  width: 8px; 
  border: 2px solid #000000;
  border-radius: 100%;  
}

/*
  Desktop App specific CSS
    https://docs.todesktop.com/
*/

/* disable user selection on buttons, links and images on desktop app */
html.todesktop button,
html.todesktop a,
html.todesktop img,
html.todesktop header {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default !important;
}

html.todesktop,
html.todesktop div {
  cursor: default !important;
}

/* make header draggable on desktop app */
html.todesktop header {
  -webkit-app-region: drag;
}
html.todesktop header button {
  -webkit-app-region: no-drag;
}

html.todesktop .logo {
  display: none;
}

.desktop-only {
  display: none;
}

html.todesktop .desktop-only {
  display: block;
}

html.todesktop .desktop-hidden {
  display: none;
}

html.todesktop header {
  margin-top: -14px;
}

html.todesktop header nav {
  margin-top: 8px;
}

html.todesktop aside {
  margin: 0px -6px;
}

html.todesktop-platform-darwin .desktop-transparent {
  background: transparent !important;
  border: none !important;
}

html.todesktop-platform-darwin.dark main.bg-default {
  background: rgba(0, 0, 0, 0.6) !important;
}

html.todesktop-platform-darwin.light main.bg-default {
  background: rgba(255, 255, 255, 0.8) !important;
}

/* 
html.todesktop aside a {
  height: 28px;
  padding: 0px 8px;
  font-size: 12px;
  color: #383438 !important
}

html.todesktop nav a:hover{
  background-color: inherit !important
}

html.todesktop nav a[aria-current="page"]{
  background: rgba(0, 0, 0, 0.1) !important;
}

html.todesktop nav a svg{
  color: #0272F7 !important
} */

/*
  Adds Utility to hide scrollbar to tailwind
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  @layer responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

/*
 * Override the default tailwindcss-forms styling (default is: 'colors.blue.600')
 * @see: https://github.com/tailwindlabs/tailwindcss-forms/issues/14#issuecomment-1005376006
 */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="checkbox"]:focus,
[type="radio"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--brand-color);
  border-color: var(--brand-color);
}

@layer base {
  @font-face {
    font-family: "Moret";
    src: url("/fonts/moret-bold-webfont.woff2") format("woff2"),
      url("/fonts/moret-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Moret";
    src: url("/fonts/moret-boldoblique-webfont.woff2") format("woff2"),
      url("/fonts/moret-boldoblique-webfont.woff") format("woff");
    font-weight: 700;
    font-style: oblique;
    font-display: swap;
  }

  @font-face {
    font-family: "Moret Book";
    src: url("/fonts/moret-book-webfont.woff2") format("woff2"),
      url("/fonts/moret-book-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Moret Book";
    src: url("/fonts/moret-bookoblique-webfont.woff2") format("woff2"),
      url("/fonts/moret-bookoblique-webfont.woff") format("woff");
    font-weight: normal;
    font-style: oblique;
    font-display: swap;
  }

  @font-face {
    font-family: "Moret";
    src: url("/fonts/moret-extrabold-webfont.woff2") format("woff2"),
      url("/fonts/moret-extrabold-webfont.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Moret";
    src: url("/fonts/moret-extraboldoblique-webfont.woff2") format("woff2"),
      url("/fonts/moret-extraboldoblique-webfont.woff") format("woff");
    font-weight: 900;
    font-style: oblique;
    font-display: swap;
  }

  @font-face {
    font-family: "Moret";
    src: url("/fonts/moret-regular-webfont.woff2") format("woff2"),
      url("/fonts/moret-regular-webfont.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Moret";
    src: url("/fonts/moret-regularoblique-webfont.woff2") format("woff2"),
      url("/fonts/moret-regularoblique-webfont.woff") format("woff");
    font-weight: normal;
    font-style: oblique;
    font-display: swap;
  }

  @font-face {
    font-family: "Moret";
    src: url("/fonts/moret-semibold-webfont.woff2") format("woff2"),
      url("/fonts/moret-semibold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Moret";
    src: url("/fonts/moret-semiboldoblique-webfont.woff2") format("woff2"),
      url("/fonts/moret-semiboldoblique-webfont.woff") format("woff");
    font-weight: 700;
    font-style: oblique;
    font-display: swap;
  }

  @font-face {
    font-family: "Sofia Pro";
    src: url("/fonts/SofiaProBoldAz.otf");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sofia Pro";
    src: url("/fonts/SofiaProBoldItalicAz.otf");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Sofia Pro";
    src: url("/fonts/SofiaProMediumAz.otf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sofia Pro";
    src: url("/fonts/SofiaProMediumItalicAz.otf");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Sofia Pro";
    src: url("/fonts/SofiaProRegularAz.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Sofia Pro";
    src: url("/fonts/SofiaProRegularItalicAz.otf");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
}

@layer components {
  .scroll-bar {
    @apply scrollbar-thin scrollbar-thumb-rounded-md dark:scrollbar-thumb-darkgray-300 scrollbar-thumb-gray-300 scrollbar-track-transparent;
  }
}

/* TODO: avoid global specific css */
/* button[role="switch"][data-state="checked"] span {
  transform: translateX(16px);
} */

@layer components {
  /* slider */
  .slider {
    @apply relative flex h-4 w-40 select-none items-center;
  }

  .slider > .slider-track {
    @apply relative h-1 flex-grow rounded-md bg-gray-400;
  }

  .slider .slider-range {
    @apply absolute h-full rounded-full bg-gray-700;
  }

  .slider .slider-thumb {
    @apply block h-3 w-3 cursor-pointer rounded-full bg-gray-700 transition-all;
  }

  .slider .slider-thumb:hover {
    @apply bg-gray-600;
  }

  .slider .slider-thumb:focus {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
  }
}

/* hide chat bubble on mobile */
@media only screen and (max-width: 768px) {
  /* Intercom FAB*/
  #launcher {
    display: none !important;
  }

  /* Zendesk FAB*/
  div[role="presentation"] > iframe {
    display: none !important;
  }

  /* Helpscout FAB*/
  .BeaconFabButtonFrame {
    margin-left: -30px;
    left: 50%;
    bottom: 28px !important;
    z-index: 1058 !important;
  }
}

::placeholder {
  @apply font-body text-base;
}

/* TODO: implement styling for react-multi-email */

/* !important to override react-dates */
.DateRangePickerInput__withBorder {
  border: 0 !important;
}
.DateInput_input {
  border: 1px solid #d1d5db !important;
  border-radius: 2px !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  color: #000;
  padding: 11px ​11px 9px !important;
  line-height: 16px !important;
}

.DateInput_input__focused {
  border: 2px solid #000 !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  padding: 10px ​10px 9px !important;
}

.DateRangePickerInput_arrow {
  padding: 0px 10px;
}

.loader {
  display: block;
  width: 30px;
  height: 30px;
  margin: 60px auto;
  position: relative;
  border-width: 4px;
  border-style: solid;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  animation: loader-inner 2s infinite ease-in;
}

.no-ring-inset {
  --tw-ring-inset: unset;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.text-inverted-important {
  color: white !important;
}

@layer utilities {
  .transition-max-width {
    -webkit-transition-property: max-width;
    transition-property: max-width;
  }
}

#timeZone input:focus {
  box-shadow: none;
}

/* react-date-picker forces a border upon us, cast it away */
.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup__input {
  padding-top: 0;
  padding-bottom: 0;
}

/* animations */
.slideInBottom {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInBottom;
}

@keyframes slideInBottom {
  from {
    opacity: 0;
    transform: translateY(30%);
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
  }
}

/* animations */
.slideInTop {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
}

@keyframes slideInTop {
  from {
    opacity: 0;
    transform: translateY(-20%);
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%);
  }
}

.fadeIn {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/**
 * Makes sure h-screen works on mobile Safari. By default h-screen
 * does not take into account the height of the address bar, causing
 * weird behaviour when scrolling — sometimes the height will be correct
 * and sometimes it won't, depending on whether the address bar is
 * in 'collapsed' state or not.
 * @see: https://benborgers.com/posts/tailwind-h-screen
 */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}